<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template>
            <ValidationObserver ref="subsPlanForm">
              <form action="#" v-if="vmSubscriptionPlanFormData && Object.keys(vmSubscriptionPlanFormData).length > 0">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="subs_plan_id">{{cvSubsPlanId}}<small>(Only Alphabets, Numbers & Dash is Allowed)</small></label>
                    <ValidationProvider
                      name="Plan Id"
                      rules="required|max:50|regex:^([a-zA-Z0-9-])*$"
                      v-slot="{ errors }">
                      <input
                        id="subs_plan_id"
                        v-model="vmSubscriptionPlanFormData.subs_plan_id"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <!-- Revenue source filter -->
                  <b-col class="col-md-12 mb-3">
                    <label for="subs_revenue_src">{{cvSubsRevenueSrc}}</label>
                    <select v-model="vmSubscriptionPlanFormData.revenue_src" class="form-control">
                      <option v-for="(src, index) of revenueSrcList" :key="(index+1)" :value="src">
                        {{src}}
                      </option>
                    </select>
                  </b-col><!-- Revenue source filter -->

                  <div class="col-md-12 mb-3">
                    <label for="subs_plan_name">{{cvSubsPlanNameLabel}}</label>
                    <ValidationProvider
                      name="Plan Name"
                      rules="required|max:50"
                      v-slot="{ errors }">
                      <input
                        id="subs_plan_name"
                        v-model="vmSubscriptionPlanFormData.subs_plan_name"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanTypeLabel}}</label>
                    <ValidationProvider
                      name="Plan Interval"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="vmSelectedSubsInterval"
                        :options="subscriptionPlanTypeObjList"
                        :placeholder="cvSelectBoxText + cvSubsPlanTypeLabel"
                        :tag-placeholder="cvSelectBoxText + cvSubsPlanTypeLabel"
                        label="spi_name"
                        track-by="id"
                        required>
                      </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="subs_plan_interval_count">{{cvSubsPlanIntervalCountLabel}}</label>
                    <ValidationProvider
                      name="Plan Interval Count"
                      rules="required|max:1"
                      v-slot="{ errors }">
                      <input
                        id="subs_plan_interval_count"
                        v-model="vmSubscriptionPlanFormData.subs_plan_interval_count"
                        type="number"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="sub_plan_module_obj_id">{{cvSubsPlanModObjId}}</label>
                    <input
                        id="sub_plan_module_obj_id"
                        v-model="vmSubscriptionPlanFormData.sub_plan_module_obj_id"
                        type="text"
                        class="form-control"/>
                  </div>

                  <div class="col-md-12 mb-3" v-if="vmSubscriptionPlanFormData.subs_plan_name">
                    <label>{{cvSubsPlanModulesLabel}}</label>
                    <br />
                    <span
                      v-for="(module) in modulesList"
                      :key="module.module_id">
                      <b-badge
                        style="cursor:pointer;"
                        :style="(selectedModuleObjList && selectedModuleObjList[module.id]) ? setModuleChipStyle(true) : setModuleChipStyle(false)"
                        class="border mr-2 mb-2 font-weight-normal"
                        variant="none"
                        @click="selectModule(module)">
                        <span>{{ module.mod_name }}</span>
                      </b-badge>
                    </span>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanCurrencyLabel}}</label>
                    <ValidationProvider
                      name="Plan Currency"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="vmSelectedSubsPlanCurr"
                        :tag-placeholder="cvSelectBoxText + cvSubsPlanCurrencyLabel"
                        :placeholder="cvSelectBoxText + cvSubsPlanCurrencyLabel"
                        :searchable="true"
                        label="currency_name"
                        track-by="id"
                        :options="cvCurrencyOptions"
                        required></multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanPriceLabel}}</label>
                    <ValidationProvider
                      name="Plan Price"
                      rules="numeric"
                      v-slot="{ errors }">
                      <input
                        v-model="vmSubscriptionPlanFormData.subs_plan_price"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanPriceGidePlusLabel}}</label>
                    <ValidationProvider
                      name="Plan Price for Gide Plus"
                      rules="numeric"
                      v-slot="{ errors }">
                      <input
                        v-model="vmSubscriptionPlanFormData.subs_plan_price_gideplus"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label>Plan Price In USD (For other countries) If Applicable</label>
                    <ValidationProvider
                      name="Plan Price In USD"
                      rules="numeric"
                      v-slot="{ errors }">
                      <input
                        v-model="vmSubscriptionPlanFormData.subs_plan_usd_price"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label>Gide Plus Price In USD (For other countries) If Applicable</label>
                    <ValidationProvider
                      name="Plan Price for Gide Plus In USD"
                      rules="numeric"
                      v-slot="{ errors }">
                      <input
                        v-model="vmSubscriptionPlanFormData.subs_plan_usd_price_gideplus"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label>{{cvSubsPlanDescLabel}}</label>
                    <ValidationProvider
                      name="Plan Desc"
                      rules="required"
                      v-slot="{ errors }">
                      <b-form-textarea
                        v-model="vmSubscriptionPlanFormData.subs_plan_desc"
                        rows="2"
                        max-rows="6"
                        required></b-form-textarea>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                  <button type="button" class="btn btn-primary" @click="subscriptionPlanEdit()">
                    {{cvSubmitBtn}}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import ApiResponse from "../../../Utils/apiResponse.js"
import Multiselect from "vue-multiselect"
import CountryMixin from "../../../Utils/Mixins/country"
import { ValidationProvider } from "vee-validate"
import { modules } from "../../../FackApi/api/modules"
import userPermission from "../../../Utils/user_permission.js"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "SubscriptionPlanEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSubscriptionPlanObj: {
      type: Object,
      default: function () {
        return {
          "subs_plan_name": "",
          "subs_plan_interval": "",
          "subs_plan_modules": "",
          "subs_plan_currency": "",
          "subs_plan_price": ""
        }
      }
    }
  },
  components: {
    Multiselect,
    ValidationProvider
  },
  data () {
    return {
      apiName: "subscriptionPlan_edit",
      cvCardTitle: "Edit Subscription Plan",
      cvCardSubHeader: "Edit Subscription Plan",
      cvSubmitBtn: "Edit",
      cvSelectBoxText: "Select ",
      cvSubsPlanId: "Plan Id",
      cvSubsPlanNameLabel: "Plan Name",
      cvSubsPlanTypeLabel: "Plan Interval",
      cvSubsPlanModObjId: "Plan Module Object Id",
      cvSubsPlanIntervalCountLabel: "Plan Interval Count",
      cvSubsPlanModulesLabel: "Select Plan for",
      cvSubsPlanCurrencyLabel: "Currency",
      cvSubsPlanDescLabel: "Plan Description",
      cvSubsPlanPriceLabel: "Price",
      cvSubsPlanPriceGidePlusLabel: "Price for Gide Plus",
      cvSubsRevenueSrc: "Revenue Types",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Subscription",
      vmSubscriptionPlanFormData: {},
      vmSubscriptionPlanType: null,
      subscriptionPlanTypeObjList: [],
      selectedModuleObjList: null,
      modulesList: [],
      vmSelectedSubsInterval: {},
      vmSelectedSubsPlanCurr: {},
      revenueSrcList: [
        "B2C",
        "B2B",
        "B2B2C",
        "B2B2A", // Affilate Codes Added by Counselors,
        "B2G" // GIDEPreneur
      ]
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mixins: [ CountryMixin ],
  async mounted () {
    socialvue.index()
    await this.getmoduleList()
    await this.subscriptionPlanTypeList()
    this.subscriptionPlanView()
  },
  methods: {
    /*
    * module_list
    */
    async getmoduleList () {
      let resp = await modules.moduleList(this)
      if (!resp.resp_status) {
        return false
      }
      this.modulesList = resp.resp_data.data
    },
    /**
    * subscriptionPlanTypeList
    */
    async subscriptionPlanTypeList () {
      try {
        let subscriptionPlanTypeListResp = await SubscriptionPlans.subscriptionPlanTypeList(this)
        if (subscriptionPlanTypeListResp.resp_status) {
          this.subscriptionPlanTypeObjList = subscriptionPlanTypeListResp.resp_data.data
        }
      }
      catch (err) {
        console.log("Exception occurred at subscriptionPlanTypeList() and Exception:", err.message)
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSubscriptionPlanFormData) {
          if (!this.vmSubscriptionPlanFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * subscriptionPlanView
     */
    async subscriptionPlanView () {
      try {
        if (this.propOpenedInModal) {
          this.vmSubscriptionPlanFormData = this.propSubscriptionPlanObj
          this.vmSubscriptionPlanFormData.subs_plan_id_copy = this.vmSubscriptionPlanFormData.subs_plan_id

          if (this.vmSubscriptionPlanFormData.subs_module_ids) {
            this.vmSubscriptionPlanFormData.subs_module_ids = this.vmSubscriptionPlanFormData.subs_module_ids.replace(/\^|\$/g, "")
            const moduleIds = this.vmSubscriptionPlanFormData.subs_module_ids.split("|")
            this.selectedModuleObjList = {}

            this.modulesList.map((mod) => {
              if (moduleIds.includes(mod.id.toString())) {
                this.$set(this.selectedModuleObjList, mod.id, mod)
              }
            })
            this.vmSelectedSubsInterval = {
              spi_name: this.vmSubscriptionPlanFormData.subs_plan_interval
            }
            this.vmSelectedSubsPlanCurr = {
              currency_id: this.vmSubscriptionPlanFormData.currency_id,
              currency_name: this.vmSubscriptionPlanFormData.currency_name,
              currency_symbol: this.vmSubscriptionPlanFormData.currency_symbol
            }
          }
        }
        else {
          let subscriptionPlanId = this.$route.params.subs_plan_id
          let subscriptionPlanViewResp = await SubscriptionPlans.subscriptionPlanView(this, subscriptionPlanId)
          if (subscriptionPlanViewResp && subscriptionPlanViewResp.resp_status) {
            this.vmSubscriptionPlanFormData = subscriptionPlanViewResp.resp_data.data
            this.vmSelectedSubsPlanCurr = {
              currency_id: this.vmSubscriptionPlanFormData.currency_id,
              currency_name: this.vmSubscriptionPlanFormData.currency_name,
              currency_symbol: this.vmSubscriptionPlanFormData.currency_symbol
            }

            this.vmSubscriptionPlanFormData.subs_module_ids = this.vmSubscriptionPlanFormData.subs_module_ids.replace(/\^|\$/g, "")
            const moduleIds = this.vmSubscriptionPlanFormData.subs_module_ids.split("|")
            this.selectedModuleObjList = {}

            this.modulesList.map((mod) => {
              if (moduleIds.includes(mod.id.toString())) {
                this.$set(this.selectedModuleObjList, mod.id, mod)
              }
            })

            this.vmSelectedSubsInterval = {
              spi_name: this.vmSubscriptionPlanFormData.subs_plan_interval
            }
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at subscriptionPlanView() and Exception:", err.message)
      }
    },
    /**
     * subscriptionPlanEdit
     */
    async subscriptionPlanEdit () {
      try {
        this.cvLoadingStatus = true

        // If B2C, B2B etc not included then include
        this.vmSubscriptionPlanFormData.revenue_src = this.vmSubscriptionPlanFormData.revenue_src ? this.vmSubscriptionPlanFormData.revenue_src : "B2C"

        // Dont do this in Edit mode else old ids will get changed. We dont want
        // this.vmSubscriptionPlanFormData.subs_plan_id = this.vmSubscriptionPlanFormData.subs_plan_id && this.vmSubscriptionPlanFormData.subs_plan_id.includes(this.vmSubscriptionPlanFormData.revenue_src) ? this.vmSubscriptionPlanFormData.subs_plan_id : `${this.vmSubscriptionPlanFormData.subs_plan_id}-${this.vmSubscriptionPlanFormData.revenue_src}`

        // Extract value of subscription plan interval object
        if (this.vmSelectedSubsInterval && Object.keys(this.vmSelectedSubsInterval).length > 0) {
          this.vmSubscriptionPlanFormData.subs_plan_interval = this.vmSelectedSubsInterval.spi_name
        }

        if (this.vmSelectedSubsPlanCurr && Object.keys(this.vmSelectedSubsPlanCurr).length > 0) {
          this.vmSubscriptionPlanFormData.subs_plan_currency = JSON.stringify(this.vmSelectedSubsPlanCurr)
        }

        if (this.selectedModuleObjList && Object.keys(this.selectedModuleObjList).length > 0) {
          this.vmSubscriptionPlanFormData.subs_plan_modules = JSON.stringify(this.selectedModuleObjList)
        }

        if (this.vmSelectedSubsModType && Object.keys(this.vmSelectedSubsModType).length > 0) {
          this.vmSubscriptionPlanFormData.subs_plan_allowed_role = this.vmSelectedSubsModType.module_abbr
        }

        this.cvLoadingStatus = true
        let subscriptionPlanAddResp = await SubscriptionPlans.subscriptionPlanEdit(this, this.vmSubscriptionPlanFormData)
        await ApiResponse.responseMessageDisplay(this, subscriptionPlanAddResp)
        if (subscriptionPlanAddResp && !subscriptionPlanAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSubscriptionPlanEditModal", subscriptionPlanAddResp.resp_data)
        }
      }
      catch (err) {
        console.error("Exception occurred at subscriptionPlanEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * setModuleChipStyle
     */
    setModuleChipStyle (active) {
      if (active) {
        return {
          "color": "#FFFFFF",
          "border-color": "#51B5FD",
          "background-color": "#51B5FD"
        }
      }
      return {
        "color": "",
        "border-color": ""
      }
    },
    /**
     * selectModule
     */
    selectModule (module) {
      if (!this.selectedModuleObjList) {
        this.selectedModuleObjList = {}
      }

      if (this.selectedModuleObjList[module.id]) {
        this.$delete(this.selectedModuleObjList, module.id)
      }
      else {
        this.$set(this.selectedModuleObjList, module.id, module)
      }
    }
  }
}
</script>
